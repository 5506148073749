<template>
  <div>
    <w-top-bar>Zonas de Envío</w-top-bar>
    <div class="h-full p-5 bg-gray-200">
      <div
        v-if="!isFindShippingZonePending"
        class="flex items-center justify-end h-12 px-1"
      >
        <!-- <div class="flex items-center text-xs">
          <w-switch
            :value="localcoupons"
            @input="localcoupons = !localcoupons"
          ></w-switch>
          <span>Mostrar Locales</span>
        </div> -->
        <router-link
          :to="{ name: 'shipping-zones-id', params: { id: 'new' } }"
          class="text-sm text-blue-500 cursor-pointer hover:text-blue-600"
          >Nueva Zona</router-link
        >
        <!-- <button @click="fake()" class="text-sm text-blue-500 cursor-pointer hover:text-blue-600">Add Fake Evento</button> -->
      </div>
      <transition
        leave-active-class="transition-all duration-300 ease-in-out"
        enter-active-class="transition-all duration-200 ease-in-out"
        enter-class="opacity-0 "
        enter-to-class="opacity-100 "
        leave-to-class="opacity-100 "
        leave-class="opacity-0 "
      >
        <w-shipping-zones v-if="!isFindShippingZonePending" :items="shippingZone" />
        <w-loading v-else />
      </transition>
    </div>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex'; // Step 1
import { mapActions } from 'vuex';
import WLoading from '../../components/WLoading.vue';

export default {
  components: { WLoading },
  name: 'shippingZoneList',
  data() {
    return {
      localcoupons: true
    }
  },
  mixins: [
    makeFindMixin({ service: 'shipping-zone' }),
  ], // Step 2
  computed: {
    shippingZoneParams() {
      let query = {}
      return {
        query: {
          ...query,
          $sort: {
            updatedAt: -1,
          },
        },
      }; 
    }
  },
  watch: {  },
  methods: {
    ...mapActions("shipping-zone", {create: "create"})
  },
};
</script>

<style lang="scss" scoped></style>
